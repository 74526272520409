@mixin text-color-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color !important;
  }
  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      @include hover-focus() {
        color: darken($color, $emphasized-link-hover-darken-percentage) !important;
      }
    }
  }
}